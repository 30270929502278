import { getAll } from "@/firebase/helpers";

export default {
    namespaced: true,
    state(){
        return{
            items:[]
        }
    },
    getters:{

    },
    actions:{
        async getAll({commit,dispatch}){
            dispatch('loaderModule/showLoader',true,{root:true});
            const tabMesures = await getAll('mesure');
            commit('setMesures',tabMesures);
            dispatch('loaderModule/showLoader',false,{root:true});
        }
    },
    mutations:{
        setMesures(state,mesures){
            state.items = mesures;
        }
    }
};