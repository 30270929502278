<template>
    <div>
        <div class="row">
            <div class="col">
                <h1>Liste</h1>
            </div>
        </div>
        <div class="row pl-3">
            <div class="col-sm-10 row">
                <div class="col-sm-2 col-md-2 mr-3">
                    <div class="row">
                        <label>Nbr. Lignes</label>
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 ml-1">
                    <div class="row">
                        <label>Page</label>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="row pl-3">
            <div class="col row">
                <div class="col-sm-2 col-md-2 mr-3">
                    <div class="form-group row">
                        <input v-model="nbrLines" type="text" class="form-control text-center">
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 ml-1">
                    <div class="form-group row">
                        <input type="number" v-model="page" class="form-control text-center">
                    </div>
                </div>
                <div class="col-sm-2 col-md-2 ml-1">
                    <div class="form-group row">
                        <button tooltip.hover title="Afficher les resultats" @click="printList" class="btn text-danger"><i class="fa-solid fa-eye fa-xl"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <input v-model="keyWord" @keyup.enter="searchTalibe" type="text" class="form-control" placeholder="Recherche...">
            </div>
        </div>
        
        <div class="d-flex p-1">
            <div class="col">
                <table class="table table-stripped table-responsive-lg">
                    <thead>
                        <tr>
                            <th><span @click="searchColumn='numero';col=$event.target">Numero Talibe</span></th>
                            <th><span @click="searchColumn='prenom';col=$event.target">Prénom</span></th>
                            <th><span @click="searchColumn='nom';col=$event.target">Nom</span></th>
                            <th class="text-right">
                                <button tooltip.hover title="Ajouter un Talibé" class="btn btn-sm text-white" @click="showLeftPart = !showLeftPart"><i class="fa-solid fa-user-plus fa-xl"></i></button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="talibe in talibes" :key="talibe.numero">
                            <td>{{ talibe.numero }}</td>
                            <td>{{ talibe.prenom }}</td>
                            <td>{{ talibe.nom }}</td>
                            <td class="text-right">
                                <button tooltip.hover title="Voir Details" @click="printList" class="btn btn-sm text-primary"><i class="fa-solid fa-eye"></i></button>
                                <button tooltip.hover title="Editer" @click="printList" class="btn btn-sm text-secondary"><i class="fa-solid fa-user-pen"></i></button>
                                <button tooltip.hover title="Supprimer" @click="printList" class="btn btn-sm text-danger"><i class="fa-solid fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <transition>
                <div class="bordered" v-if="showLeftPart" style="position:relative">
                    <button @click="this.showLeftPart=false;" class="btn btn-sm text-danger" style="position:absolute;top:0;right:0">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                    <form-add-talibe-component/>
                    <!-- <form class="col">
                        <div id="back" v-show="this.loaderForm">
                        <div class="loading"></div>
                        </div>
                        <h6 class="bg-p text-white p-1">Nouvel Eleve</h6>
                        <hr>
                        <div class="form-group">
                        <label for="numero">Numéro</label>
                            <input type="text" class="form-control" id="numero" placeholder="Numéro" v-model="this.talibe.numero">
                        </div>
                        <div class="form-group">
                        <label for="prenom">Prénom</label>
                        <input type="text" id="prenom" class="form-control" placeholder="Prénom" v-model="this.talibe.prenom">
                        </div>
                        <div class="form-group">
                        <label for="nom">Nom</label>
                        <input type="text" id="nom" class="form-control" placeholder="Nom" v-model="this.talibe.nom">
                        </div>
                        <div class="form-group d-flex justify-content-center align-items-center">
                        <button @click="addTalibe" type="button" class="btn btn-sm btn-outline-success"><small>Ajouter</small> <i class="fa-solid fa-floppy-disk"></i></button>
                        </div>
                    </form> -->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import FormAddTalibeComponent from '@/components/FormAddTalibe.vue';
// import {talibes} from '@/data/talibe';
export default {
    components:{
        FormAddTalibeComponent
    },
    data(){
        return {
            page: 1,
            nbrLines: 10,
            keyWord: '',
            searchColumn: 'nom',
            col:null,
            talibe:{ numero:'',prenom:'',nom:'' },
            showLeftPart: false,
        }
    },
    computed:{
        talibes(){
            return this.$store.state.talibeModule.talibes;
        }
    },
    watch:{
        col(newValue,oldValue){
            newValue.className = 'select-column'
            if(oldValue){
                oldValue.className = ''
            }
        }
    },
    methods:{
        searchTalibe(){
            this.$store.dispatch('talibeModule/seach',{keyWord:this.keyWord,searchColumn:this.searchColumn});
        },
        printList(){
            const starAt = (this.page -1) * this.nbrLines + 1;
            this.$store.dispatch('talibeModule/getAll',{limit:this.nbrLines,startAt:starAt,orderBy:'numero'});
        }
    },
    created(){
        // for (let i = 0; i < talibes.length; i++) {
            
        //     this.$store.dispatch('talibeModule/add',{
        //         data: {
        //             id: talibes[i].numeroTalibe,
        //             numero: talibes[i].numeroTalibe,
        //             prenom:talibes[i].prenomTalibe,
        //             nom:talibes[i].nomTalibe
        //         },
        //         onSuccess: ()=>{
        //             console.log('Cooool');
        //         }
        //     });
        // }
        this.$store.dispatch('talibeModule/getAll');
    }
}
</script>

<style scoped>
*{
    box-sizing: border-box;
}
th span{
    cursor: pointer;
}
.select-column{
    border-right: 2px solid;
    border-left: 2px solid;
    color: aliceblue;
    padding: .5rem;
    border-radius: 20%;
}
/* form */
input{
  border: 3px solid #C14F67 !important;
}
input:focus{
  border: 5px solid #76041c !important;
}
/* ANIMATION */
.v-enter-from {
  transform: translateX(100%)
}
.v-enter-to {
  transform: translateX(0);
}
.v-enter-active {
  transition: transform .3s ease-out
}
.bordered{
  border-left: 2px solid #76041c;
  padding: 1.5em;
}
</style>