export default {
    namespaced: true,
    state(){
        return{
            waiting:false
        }
    },
    getters:{

    },
    actions:{
        async showLoader({commit},state){
            //
            commit('setWaiting',state);
        }
    },
    mutations:{
        setWaiting(state,waiting){
            state.waiting = waiting;
        }
    }
};