<template>
  <loader-screen/>
  <NavBar/>
  <!-- <hr> -->
  <div class="container" style="position:relative">
    <router-view />
  </div>
</template>

<script>
import LoaderScreen from './components/LoaderScreen';
import NavBar from './components/NavBar';
export default {
  name: 'App',
  components: {
    NavBar,
    LoaderScreen
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
*{
  font-family: 'Montserrat', sans-serif;
}
.container{
  border: 2px solid #76041c;
  margin-top: 3em;
}
.s-color{
  color : #C14F67
}
.p-color{
  color: #76041c;
}
.bg-s{
  background-color : #C14F67
}
.bg-p{
  background-color: #76041c;
}
tr{
  border-top: 2px solid #C14F67;
}
thead{
  background-color: #C14F67;
}
select{
  border: 3px solid #76041c !important;
}
input{
  border: 3px solid #C14F67 !important;
}
input:focus{
  border: 3px solid #76041c !important;
}
/* ANIMATION */
.v-enter-from {
  transform: translateX(100%)
}
.v-enter-to {
  transform: translateX(0);
}
.v-enter-active {
  transition: transform .3s ease-out
}
.bordered{
  border-left: 2px solid #76041c;
  padding: 1.5em;
}
/* Chargement */
#back{
  background-color: rgba(193, 79, 103,0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*  */
#back.off{
  display: none;
}
#back.on{
  display: block;
}
.loading{
    border: 4px solid #F2F2F2;
    width: 60px;
    height: 60px;
    border-color: #76041c transparent #76041c transparent;
    border-radius: 50%;
    animation: rotation 1.3s linear infinite;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
    color: #F2F2F2;
}
@keyframes rotation {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
/* fin charment */
</style>
