import { FIREBASE_DAARA_ID } from "@/config/const";
import { addDoc, collection, doc, getDocs, limit, orderBy, query, setDoc, startAt, where } from "firebase/firestore";
import db from "./init";



export async function search(path,{keyWord,searchColumn}){
    const collectionRef = getCollectionRef(path);
    let q = query(collectionRef,where(searchColumn,'>=',keyWord),where(searchColumn,'<=',keyWord+'z'));
    const snap = await getDocs(q);
    const datas = snap.docs.map(d=>d.data());
    return datas;
}
//
export async function getSingleBy({path,column,value}){
    const collectionRef = getCollectionRef(path);
    let q = query(collectionRef,where(column,'==',value),limit(1));
    const data = await getDocs(q);
    return data.size ? {id:data.docs[0].id,...data.docs[0].data()} : undefined;
}
//
export async function getAll(path,options=undefined,paginate=false){
    const collectionRef = getCollectionRef(path);
    let q;
    if(paginate && options && options.limit && options.startAt && options.orderBy){
        q = query(collectionRef,orderBy(options.orderBy),startAt(options.startAt),limit(options.limit));
    }else{
        console.log(collectionRef)
        q = query(collectionRef);
    }
    const snap = await getDocs(q);
    const datas = snap.docs.map(d=>d.data());
    return datas;
}
//
export async function addOne(path,data){
    const collectionRef = getCollectionRef(path);
    // await setDoc(doc(db,'daara', daaraId,'progression_mensuel',mois,'talibes',data.numero+''),data);
    let result;
    if(data.id){
        //Add doc with given id
        const {id,...dataWithoutId} = {...data};
        result = await setDoc(doc(collectionRef,id+''),dataWithoutId);
    }else{
        //Add doc with generated id
        result = await addDoc(collectionRef,data);
    }
    return result;
}

function checkPath(path){
    return path.startsWith('/') ? path : `/${path}`;
}
function getCollectionRef(path){
    path = checkPath(path);
    return collection(db, 'daara', `${FIREBASE_DAARA_ID}${path}`);
}