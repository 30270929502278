import "./assets/css/vendor/dropzone.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";


import { createApp } from 'vue';
import App from './App.vue';
//
import router from './router';
//
import store from './store';
//
//
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { getAuth } from "firebase/auth";


let app = createApp(App);
// app.use(require('vue-cookies'));
//
app.use(store)
//
    .use(router)
    .use(Toast);
//
app.mount('#app')

store.dispatch('userModule/onAuthChange',()=>{

  const isAuthenticated = !!getAuth().currentUser;
  console.log('yess'+isAuthenticated)
  if(isAuthenticated){
    router.push('/home');
  }
});
