<template>
    <div class="container">
        <img :src="logo" alt="">
    </div>
</template>
<script>
import { Logo } from '@/config/const'
export default {
    
    data(){
        return {
            logo : 'dskjfhdskljkd'
        }
    },
    mounted(){
        this.logo = Logo.LOGO;
    }
}
</script>

<style scoped>
    .container{
        display: flex;
        justify-content: center;
        border: none;
    }
    img{
        width: 50%;
    }
</style>