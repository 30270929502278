import { createStore } from "vuex";

import talibeModule from './modules/talibe';
import mesureModule from './modules/mesure';
import quranModule from './modules/quran';
import userModule from './modules/user';
import toastModule from "./modules/toast";
import loaderModule from "./modules/loaders";
import progressionModule from "./modules/progression";

export default createStore({
    modules:{
        talibeModule,
        mesureModule,
        quranModule,
        userModule,
        toastModule,
        loaderModule,
        progressionModule
    }
});