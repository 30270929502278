<template>
  <!-- <div id="loader-page" v-if="waiting">
      LOADING
  </div> -->
    <div id="back" v-show="this.waiting">
        <div class="loading"></div>
    </div>
</template>

<script>
export default {
    computed:{
        waiting(){
            return this.$store.state.loaderModule.waiting;
        }
    }
}
</script>

<style>
    #loader-page{
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 300%;
        font-weight: 900;
}




/* Chargement */
#back{
  background-color: rgba(193, 79, 103,0.1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*  */
#back.off{
  display: none;
}
#back.on{
  display: block;
}
.loading{
    border: 4px solid #F2F2F2;
    width: 60px;
    height: 60px;
    border-color: #76041c transparent #76041c transparent;
    border-radius: 50%;
    animation: rotation 1.3s linear infinite;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
    color: #F2F2F2;
}
@keyframes rotation {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
</style>