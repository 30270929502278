<template>
  <nav class="navbar navbar-expand-lg navbar-green">
  <router-link class="navbar-brand" to="/">Daara Luqman</router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <!-- <a class="nav-link white-text" href="#">Liste</a> -->
        <!-- <router-link to="/list" class="nav-link">Liste</router-link> -->
        <router-link :to="{name: 'list'}" class="nav-link">Talibes</router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'progression'}" class="nav-link">Progression</router-link>
      </li>
    </ul>
    <ul class="navbar-nav mr-0">
      
      <template v-if="isAuthenticated">
        <li class="nav-item">
          <router-link :to="'#'" class="nav-link text-white">{{ connectedUser.username }}</router-link>
        </li>
        <li class="nav-item">
        <button @click="this.$store.dispatch('userModule/logout')" class="btn btn-sm btn-danger">
          <i class="fa-solid fa-power-off"></i>
        </button>
      </li>
      </template>
      <template v-else>
        <li class="nav-item">
        <router-link :to="{name: 'login'}" class="btn btn-sm btn-success">
          <i class="fa-solid fa-power-off"></i>
        </router-link>
      </li>
      </template>
      
      
    </ul>
  </div>
</nav>
</template>

<script>
import useAuth from '@/compositions/useAuth';
export default {
    name : 'NavBar',
    setup() {
      return useAuth();
    }
}
</script>

<style>
.navbar-green{
    /* background-color: #09351C; */
    background-color: #76041c;
    color: white;
}
a{
    color: white;
    font-size: 0.9em;
}
.router-link-exact-active, a:hover{
    color: #A02B44;
    border-bottom: 2px solid white;
}
</style>