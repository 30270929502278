<template>

    <form class="col">
      <div id="back" v-show="this.loaderForm">
        <div class="loading"></div>
      </div>
      <h6 class="bg-p text-white p-1">Nouvel Eleve</h6>
      <hr />
      <div class="form-group">
        <label for="numero">Numéro</label>
        <input
          type="text"
          class="form-control"
          id="numero"
          placeholder="Numéro"
          v-model="this.talibe.numero"
        />
      </div>
      <div class="form-group">
        <label for="prenom">Prénom</label>
        <input
          type="text"
          id="prenom"
          class="form-control"
          placeholder="Prénom"
          v-model="this.talibe.prenom"
        />
      </div>
      <div class="form-group">
        <label for="nom">Nom</label>
        <input
          type="text"
          id="nom"
          class="form-control"
          placeholder="Nom"
          v-model="this.talibe.nom"
        />
      </div>
      <div class="form-group d-flex justify-content-center align-items-center">
        <button
          @click="addTalibe"
          type="button"
          class="btn btn-sm btn-outline-success"
        >
          <small>Ajouter</small> <i class="fa-solid fa-floppy-disk"></i>
        </button>
      </div>
    </form>
</template>

<script>
export default {
    data(){
        return {
            talibe:{ numero:'',prenom:'',nom:'' },
            loaderForm: false
        }
    },
    methods:{
        async addTalibe(){
          this.loaderForm = true;
          this.$store.dispatch('talibeModule/add',{
              data: {
                  id: this.talibe.numero,
                  numero: this.talibe.numero,
                  prenom: this.talibe.prenom,
                  nom: this.talibe.nom
              },
              onSuccess: ()=>{
                  this.loaderForm = false;
                  this.talibe = {numero:'',prenom:'',nom:''}
              }
          });        
      },
    }
};
</script>

<style>
</style>