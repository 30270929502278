import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { setDoc, doc, getDoc, updateDoc} from 'firebase/firestore';
import db  from '@/firebase/init';
import { FIREBASE_DAARA_ID } from '@/config/const';
import router from '@/router';
export default {
    namespaced: true,
    state() {
        return {
            data: null,
        }
    },
    getters:{ // LIKE COMPUTED PROPERTY
        isAuthenticated(state){
            return !!state.data; // return false if data is null
        }
    },
    actions:{
        async login({dispatch},{username, password}){
            try {
                const email = `${username}@${FIREBASE_DAARA_ID}.sn`;
                await signInWithEmailAndPassword(getAuth(),email,password);
            } catch (e) {
                dispatch('toastModule/error',e.message,{root:true});
            }finally{
                // commit('setAuthIsProcessing',false);
                console.log('Finally')
            }
        },
        async logout({commit}){
            try {
                await signOut(getAuth());
                commit('setUser',null);
                //Redirection vers /login
                router.push({name:'login'});
            } catch (e) {
                console.log('Cannot sign out!!!');
            }
        },
        onAuthChange({dispatch},callback){//context.dispatch
            // commit('setAuthIsProcessing',true);
            onAuthStateChanged(getAuth(),async (user)=>{
                dispatch('loaderModule/showLoader',true,{root:true});
                if(user){
                    await dispatch('getUserProfile',user);
                    // commit('setAuthIsProcessing',false);
                    dispatch('loaderModule/showLoader',false,{root:true});
                    callback(user);
                }else{
                    dispatch('loaderModule/showLoader',false,{root:true});
                   console.log('Logged out!');
                //    commit('setAuthIsProcessing',false);
                   callback(null);
                }
             });
        },
        async updateProfile({commit, dispatch},{ data, onSuccess }){
            //Update Firestore
            const userRef = doc(db,'users',data.id);
            await updateDoc(userRef,data);
            //update the local store
            commit('updateProfile',data);
            //show Toast
            dispatch('toastModule/success','Profile has been updated!',{ root: true });
            //callback to close modale:
            onSuccess();
        },
        async getUserProfile({ commit },user){
            // const querySnap = await getDocs(q);
            // const exchanges = querySnap.docs.map(doc => doc.data());
            // A FAIRE:
            // - Recuperer les mesures
            // - recuperer les parametres
            const docRef = doc(db,'daara',FIREBASE_DAARA_ID,'users',user.uid);
            const docSnap = await getDoc(docRef);
            const userProfile = docSnap.data();
            const userWithProfile = {
                id: user.uid,
                username: user.email.split('@')[0],
                ...userProfile
            }
            commit('setUser',userWithProfile);
        },
        async register({dispatch},{password, username, firstname,lastname}){
            // commit('setAuthIsProcessing',true);
            // commit('setAuthError','');
            //
            const email = `${username}@${ FIREBASE_DAARA_ID }.sn`;
            console.log(email)
            try {
                const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
                dispatch('createUserProfile',{
                    id: user.uid,
                    firstname,
                    lastname
                });
                dispatch('toastModule/success','Utilisateur enregistrer avec succees!',{root:true});
                return user;
            } catch (e) {
                // commit('setAuthError',e.message);
                dispatch('toastModule/error',e.message,{root:true});
            } finally{
                // commit('setAuthIsProcessing',false);
                console.log('Finally');
            }
        },
        async createUserProfile(_, {id, ...profile}){
            await setDoc(doc(db,'daara', FIREBASE_DAARA_ID,'users',id),profile);
        }
    },
    mutations:{
        updateProfile(state, profile){
            state.data = { ...state.data, ...profile }
        },
        setUser(state, user){
            state.data = user;
        },
        setAuthIsProcessing(state, isProcessing){
            state.auth.isProcessing = isProcessing;
        },
        setAuthError(state, error){
            state.auth.error = error;
        }
    }
}