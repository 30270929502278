import { addOne, getAll, getSingleBy, search } from "@/firebase/helpers";

export default {
    namespaced: true,
    state(){
        return {
            talibes: []
        }
    },
    getters:{

    },
    actions:{
        async getTalibeByNumero(_,numero){
            return await getSingleBy({path:'talibes',column:'numero',value:numero})
        },
        async seach({commit,dispatch},options){
            dispatch('loaderModule/showLoader',true,{root:true});
            const talibes = await search('talibes',options);
            commit('setTalibes',talibes);
            dispatch('loaderModule/showLoader',false,{root:true});
        },
        async getAll({commit,dispatch},options={limit:10,startAt:1,orderBy:'numero'}){
            dispatch('loaderModule/showLoader',true,{root:true});
            const {limit,startAt,orderBy} = options;
            const talibes = await getAll('talibes',{limit:limit,startAt:startAt,orderBy:orderBy},true);
            // const talibes = await getAll('talibes',{limit:5,startAt:'LY',orderBy:'nom'},true);
            commit('setTalibes',talibes);
            dispatch('loaderModule/showLoader',false,{root:true});
        },
        async add({dispatch},{data,onSuccess}){
            dispatch('loaderModule/showLoader',true,{root:true});
            const r = await addOne('talibes',data);
            dispatch('loaderModule/showLoader',false,{root:true});
            dispatch('toastModule/success','Talibé ajouté avec succès.',{ root: true });
            onSuccess(r);
        }
    },
    mutations:{
        setTalibes(state,data){
            state.talibes = data;
        }
    }
};