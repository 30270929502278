import { useStore } from 'vuex';
import { computed } from 'vue';

export default function useAuth(){
    const store = useStore();
    const {state} = store;
    //
    const error = computed(() => state.userModule.auth.error);
    const isProcessing = computed(()=>state.userModule.auth.isProcessing);
    const isAuthenticated = computed(()=> store.getters['userModule/isAuthenticated']);
    const connectedUser = computed(()=>state.userModule.data);
    //
    return {
        error,
        isProcessing,
        isAuthenticated,
        connectedUser
    }
}