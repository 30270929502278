export class Msg{
    static HIZB_NOT_FOUND = "Ce numero n'existe pas";
}

export class Address{
    static FULL_ADR = `RUFISQUE OUEST CITE GABON VILLA N°143 - DAKAR 
    <br>Tel: (+221) 77 355 15 14 / (+221) 77 666 71 35
    <br>
    N° Compte bancaire CBAO: 035192054901-01 Dakar-Sénégal
    <br>
    Email: luqmanealhakiim@gmail.com`;
    //
    static SHORT_ADR = 'RUFISQUE OUEST CITE GABON VILLA N°143 - DAKAR';
    static TEL = 'Tel: (+221) 77 355 15 14 / (+221) 77 666 71 35';
    static RIB = 'N° Compte bancaire CBAO: 035192054901-01 Dakar-Sénégal';
    static EMAIL = 'Email: luqmanealhakiim@gmail.com';
}

export class Logo{
    static LOGO = '/logo/logo.png';
    static LOGO_SMALL = '/logo/logo2.png';
    static HEADER = '/logo/enteteLuqman.png';
}

export const FIREBASE_DAARA_ID = 'wxiJ6Adr7tchFQcASum6';