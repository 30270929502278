// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBPq2LUGhKa68MouuGogv57QWupyDt4QGA",
  authDomain: "daara-app.firebaseapp.com",
  projectId: "daara-app",
  storageBucket: "daara-app.appspot.com",
  messagingSenderId: "1041776396891",
  appId: "1:1041776396891:web:60abbc4403b340c3928d7e",
  measurementId: "G-BYFM22S5Q7"
};

initializeApp(firebaseConfig);
//
const db = getFirestore();

export default db;