import { addOne, getAll } from "@/firebase/helpers";

export default {
    namespaced: true,
    state(){
        return{
            items:[],
            period: null
        }
    },
    getters:{
        getProgressionToPrint(state){
            return state.items;
        }
    },
    actions:{
        async saveMemorisationDuMois({dispatch},{data,month,onSuccess}){
            dispatch('loaderModule/showLoader',true,{root:true});
            await addOne(`progression_mensuel/${month}/talibes`,{id:data.numero,...data});
            dispatch('loaderModule/showLoader',false,{root:true});
            dispatch('toastModule/success','Progression à jour.',{ root: true });
            onSuccess();

        },
        async getProgressionMensuel({commit,dispatch},month=null){
            dispatch('loaderModule/showLoader',true,{root:true});
            const progressions = await getAll(`progression_mensuel/${month}/talibes`);
            const progressionsWithChecked = progressions.map(p => ({...p,checked:false}));//Ajout de l'attibut checked pour cocher ou pas au niveau de la liste
            commit('setProgressionMensuel',progressionsWithChecked);
            commit('setPeriod',month);
            dispatch('loaderModule/showLoader',false,{root:true});
        }
    },
    mutations:{
        setProgressionMensuel(state,progressions){
            state.items = progressions;
        },
        setPeriod(state,p){
            state.period = p;
        }
    }
};