import { createRouter, createWebHistory } from 'vue-router';
//

import ListPage from '../views/ListPage';
import HomePage from '../views/HomePage';
import NotFound from '../views/NotFound';
import { getAuth } from 'firebase/auth';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '/list',
            name: 'list',
            component: ListPage
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/user/LoginPage')
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('../views/user/RegisterPage')
        },
        {
            path: '/progression',
            name: 'progression',
            component: () => import('../views/progression/ProgressionMensuel'),
        },
        {
            // path: '/bulletin/:month',
            path: '/bulletin',
            name: 'print',
            component: () => import('../views/progression/PrintProgressionMensuel')
        },
        {
            path: '/contact',
            name: 'contact',
            // component: ContactPage OR By lazy lodading
            component: () => import('../views/ContactPage')
        },
        {
            path: '/home',
            redirect: '/'
        },
        {
            path: '/:catchAll(.*)',
            name: 'notFound',
            component: NotFound
        }
    ]
});


//Les acces:
router.beforeEach((to,_,next)=>{
    const isAuthenticated = !!getAuth().currentUser;
    console.log(`is auth : ${ isAuthenticated }`)
    if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
    else next()
});


export default router;